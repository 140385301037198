import React, { useState } from 'react';
import Smile from '../../../../icons/papabonus/smile-icon.svg';
import classNames from 'classnames/bind';

//Styles
import styles from './Content.styl';
import { useIntl } from 'react-intl';
import RecoveryPasswordFirstStepForm from '../../../../components/Forms/RecoveryPasswordFirstStepForm';
import { getRoute, paths } from '../../../../entry/routes';
import Container from '../../../../components/Container';
import RecoveryPasswordSecondStepForm from '../../../../components/Forms/RecoveryPasswordSecondStepForm';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

const Content = ({ onClose }) => {
  const { isPhone } = useSelector(state => state.responsive);

  const intl = useIntl();
  const [stage, setStage] = useState(1);
  const prevStage = () => {
    setStage(stage - 1);
  };
  const nextStage = () => {
    setStage(stage + 1);
  };
  const goToSignIn = () => {
    history.push(getRoute(paths.signin));
  };
  return (
    <>
      <section className={cx('ModalContainer__icon')}>
        <Smile width={46} height={46} />
      </section>
      {stage === 1 && <>
        <section className={cx('ModalContainer__header')}>
          {intl.formatMessage(
            { id: 'newsite.modal.header' },
          )}
        </section>
        <section className={cx('ModalContainer__text', { ModalContainer__text_mobile: isPhone })}>
          {intl.formatMessage({ id: 'newsite.modal.text' })}
        </section>
      </>}

      <Container className={cx('ModalContainer__body')}>
        {stage === 1 && (
          <RecoveryPasswordFirstStepForm onCancel={onClose} onNext={nextStage} />
        )}
        {stage === 2 && (
          <RecoveryPasswordSecondStepForm
            onCancel={prevStage}
            goToSignIn={goToSignIn}
            className={cx('ModalContainer__form')}
            fromModal={true}
          />
        )}
        {/*{stage === 3 && (*/}
        {/*  <React.Fragment>*/}
        {/*    <FormattedMessage id="recoveryPassword.successMessage.text">*/}
        {/*      {msg => (*/}
        {/*        <SuccessMessage text={msg}>*/}
        {/*          /!*<Button onClick={goToSignIn}>*!/*/}
        {/*          /!*  <FormattedMessage id="button.signIn" />*!/*/}
        {/*          /!*</Button>*!/*/}
        {/*        </SuccessMessage>*/}
        {/*      )}*/}
        {/*    </FormattedMessage>*/}
        {/*  </React.Fragment>*/}
        {/*)}*/}
      </Container>
    </>
  );
};

export default Content;
