import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// State
import { toggleHomeCatalog } from '../../state/modules/ui/actions';
import { customScrollTo } from '../../utils/customScrollTo';

export default function CategoryNavItem(props) {
  const { className, alias, children, style } = props;

  const dispatch = useDispatch();
  const elementRef = useRef(null);

  const handleScroll = () => {
    elementRef.current = document.querySelector(`#${alias}`);

    if (!elementRef.current) {
      return;
    }

    dispatch(toggleHomeCatalog(false));

    setTimeout(() => {
      customScrollTo(elementRef.current, { block: 'start' });
      dispatch(toggleHomeCatalog(true));
    }, 300);
  };

  return (
    <span
      role="menuitem"
      tabIndex={0}
      onClick={handleScroll}
      className={className}
      onKeyPress={e => e.key === 'Enter' && handleScroll()}
      style={style}
    >
      {children}
    </span>
  );
}

CategoryNavItem.defaultProps = {
  className: '',
  style: undefined,
};

CategoryNavItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alias: PropTypes.string.isRequired,
  style: PropTypes.object,
};
